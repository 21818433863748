import React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import routes from '../constants/routes'
import MainTemplate from '../components/templates/Base'
import { ProfileMinor, Brand } from '../svg'

const MainStyle = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;

    &.subtitle {
      font-size: 20px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  > button {
    margin-top: 2em;
    font-size: 14px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span + span {
      margin-left: 1em;
    }
  }
`

const Main = () => {
  const router = useRouter()

  const handleClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    router.push(routes.auction)
  }

  return (
    <MainStyle>
      <div>
        <Brand />
      </div>
      <div className='subtitle'>NFT Admin</div>
      <button
        className='Polaris-Button Polaris-Button--primary'
        onClick={handleClick}>
        <div>
          <span>
            <ProfileMinor />
          </span>
          <span>Log In</span>
        </div>
      </button>
    </MainStyle>
  )
}

const Home: React.FC = () => {
  return <MainTemplate main={<Main />} />
}

export default Home
